(function(site, $, GlobalServiceBus) {
  var formCaptureObj = {}, linkCaptureObj = {};
  var drupalAltImgSettings = Drupal.settings.analytics ? Drupal.settings.analytics.alt_image_tagging_enabled : false;
  // Flag to avoid bubbling on alt image click
  var altImageClickCheck = false;

  Drupal.behaviors.analyticsBehavior = {

    attached: 0,

    findElementIndex: function(arr, value) {
      return _.findIndex(arr, function(elem) {
        return elem === value;
      });
    },

    linkToPage: function() {
      window.open(linkCaptureObj.href, linkCaptureObj.target);
    },

    setLinkCapture: function(href, target) {
      linkCaptureObj.href = href;
      linkCaptureObj.target = target;
    },

    submitForm: function() {
      formCaptureObj.form.off('submit');
      formCaptureObj.form.trigger('submit');
    },

    stripOutMarkup: function(str) {
      return str.replace(/(<([^>]+)>)/ig, '');
    },

    // Accepts an array of PRODUCT_IDS, returns an array of positions
    getProductPositions: function(productIds) {
      var positions = [];
      var i;
      var j = productIds.length;
      if (window.hasOwnProperty('prodcat') && prodcat.hasOwnProperty('data') && prodcat.data.hasOwnProperty('pids')) {
        for (i = 0; i < j; i++) {
          positions.push(this.findElementIndex(prodcat.data.pids, productIds[i]));
        }
      }
      return positions;
    },

    // Examples of brand specific overrides for event handling

    addToCart: function(eventData) {
      site.track.addToCart(Object.assign({}, eventData));
    },

    addToFavorites: function(eventData) {
      site.track.addToFavorites(Object.assign({}, eventData));
    },

    removeFromCart: function(eventData) {
      site.track.removeFromCart(Object.assign({}, eventData));
    },

    // End examples brand specific overrides for event handling

    attach: function(context) {
      // all code here
      var self = this;

      if (self.attached) {
        return;
      }

      // Track Brand Logo
      $('.site-header__menu-logo a, a.js-analytics-brand-logo', context).on('click', function(event) {
        var obj;
        event.preventDefault();
        self.setLinkCapture($(this).attr('href'), '_self');
        obj = {
          event_name: 'logo_click',
          event_category: 'global',
          event_action: 'logo clicked'
        };
        site.track.evtLink(obj, self.linkToPage);
      });

      // Track Product Click
      $(document).on('click', '.js-product-brief a, .js-spp-link', function () {

        $('.slick-track .js-product-brief, .js-product-brief-view .js-product-brief').each(function(index) {
          $(this).attr('data-index', index);
        });
        var $prodElem = $(this);
        var prodId = $prodElem.closest('.js-product-brief').attr('data-product-id');
        var incStr = prodId.includes('PROD');
        if (!incStr) {
          prodId = 'PROD' + prodId;
        }
        var prodPosition = Number($prodElem.closest('.js-product-brief').data('index')) + 1;
        var prodName = $prodElem.closest('.js-product-brief, .js-grid-item-product').find('.js-product-name-wrapper').text().trim();
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name:[prodName]
          });
        }
      });

      // Product click on Recommendation section
      recomProductSelectors = [
        '.recommended-item a.thumb',
        '.recommended-item .product_name a',
        '.recommended-item a.recommended-item__thumb-link',
        '.recommended-item a.recommended-item__product-name-link'
      ];
      $(document).on('click', recomProductSelectors.join(', '), function () {
        var $prodElem = $(this);
        var $prodHref = $prodElem.attr('href');
        var splitprod = $prodHref.split('/');
        var prodId = 'PROD' + splitprod[3];
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId]
          });
        }
      });

      // Track Quick Shop
      $('.js-quickshop-launch-overlay', context).on('click', function() {
        var obj;
        var $targetElem = $(this);
        var catName = $('.content > article', context).attr('trackname');
        var prodElem = $targetElem.closest('.js-product-grid-item');
        var prodId = $targetElem.attr('data-product-id');
        var prodName = $.trim(self.stripOutMarkup(prodElem.find('.js-product-brief').find('.product-brief__name a').html()));
        obj = {
          event_label: prodName + ' - ' + prodId,
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_catagory_name: [catName],
          product_price: [prodElem.find('.js-product-price').text().replace(/\s+/g, ' ').trim()]
        };
        site.track.quickView(obj);
      });

      // Track MPP Filters
      var mppElements = [
        '.js-mpp-filter-menu__option',
        '.js-mpp-sort-menu__option'
      ];
      $(mppElements.join(', ')).on('click', function() {
        var obj, elem;
        elem = $(this);
        obj = {
          event_label: elem.text().trim()
        };
        site.track.evtAction('filterProducts', obj);
      });

      // CHECKOUT EVENTS

      // Track guest user checkout
      $('#checkout_signin_new_user , #checkout_signin_guest_user, .js-analytics-guest-user', context).on('submit', function() {
        var obj = {};
        site.track.evtAction('checkoutGuestUser', obj);
      });

      // Track return user checkout
      $("form[name$='checkout_signin'], form[name$='signin']").on('submit', function() {
        var obj = {};
        site.track.evtAction('checkoutReturnUser', obj);
      });

      // Track Payment Method
      var paymentMethodSelectors = [
        '#checkout_review input.js-payment-checkout',
        '#checkout_billing input.js-payment-checkout',
        '#checkout_billing input.js-submit-payment',
        '.js_analytics_checkout_payment',
        '.js_analytics_mobile_payment',
        '#checkout_shipping input.form-submit',
        '#checkout_review .js-analytics-review-submit',
        '.js-analytics-payment-click'
      ];
      $(document).on('click', paymentMethodSelectors.join(', '), function() {
        var paymentLabel = '';
        var paymentTypePP = [
          'PayPal',
          'PP',
          'PAYMENT_OPTION_PAYPAL'
        ];
        var paymentTypeCC = [
          'Credit Card',
          'CC',
          'PAYMENT_OPTION_MC',
          'PAYMENT_OPTION_CC',
          'PAYMENT_OPTION_VISA',
          'Pelecard',
          'wcard'
        ];
        var paymentTypeCOD = [
          'PAYMENT_OPTION_COD',
          'COD'
        ];
        var paymentType = $('input[name=PP_NAME]:checked').val() || $('input[name=PAYMENT_TYPE]:checked').val() || $('input[name=PAYMENT_OPTION]:checked').val() || $('input[name=PP_PAYMENT_METHOD]:checked').val();
        if ($.inArray(paymentType, paymentTypePP) > -1) {
          paymentLabel = 'paypal';
        } else if ($.inArray(paymentType, paymentTypeCC) > -1) {
          paymentLabel = 'creditcard';
        } else if ($.inArray(paymentType, paymentTypeCOD) > -1) {
          paymentLabel = 'cashondelivery';
        }
        var obj = {
          event_label: paymentLabel
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });

      // Track Paypal Express Check-out
      $('a.js-paypal-checkout').on('click', function() {
        var obj = {
          event_label: 'paypal'
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });

      // Extole Refer A friend
      $(document).on('submit', '.extole-js-email-panel-email-share-form', function() {
        var userEmail = '';
        var name = '';
        var referEmail = '';
        var obj = '';
        var prod = $(this);
        var referMethod = '';
        referMethod = $('.extole-panel-link--active').attr('class').split(' ')[2].split('-')[1];
        userEmail = prod.find('.extole-input .extole-tokenizer-token').first().text();
        name = prod.find('.extole-js-user-first-name').val();
        referEmail = prod.find('.extole-recipient-emails .extole-tokenizer-token').text();
        if (userEmail && name && referEmail) {
          obj = {
            'event_category': 'Refer_friend',
            'event_action': 'Refer triggered',
            'event_label': 'Refer_' + referMethod + ' - ' + document.location.href
          };
          site.track.evtLink(obj);
        }
      });
      (function($) {
        var extoleConfirm = [
          '.extole-js-facebook-panel-capture-email-form',
          '.extole-js-twitter-panel-capture-email-form',
          '.extole-js-messenger-panel-capture-email-form'
        ];
        $(document).on('submit', extoleConfirm.join(', '), function() {
          var token = '';
          var obj = '';
          var prod = $(this);
          var referMethod = '';
          referMethod = $('.extole-panel-link--active').attr('class').split(' ')[2].split('-')[1];
          token = prod.find('.extole-tokenizer-token').text();
          if (token) {
            obj = {
              'event_category': 'Refer_friend',
              'event_action': 'Refer triggered',
              'event_label': 'Refer_' + referMethod + ' - ' + document.location.href
            };
            site.track.evtLink(obj);
          }
        });

        var extoleDefault = [
          '.extole-js-twitter-panel-share-form',
          '.extole-js-facebook-panel-share-form',
          '.extole-js-messenger-panel-share-form'
        ];
        $(document).on('submit', extoleDefault.join(', '), function() {
          var obj = '';
          var referMethod = '';
          var token = '';
          referMethod = $('.extole-panel-link--active').attr('class').split(' ')[2].split('-')[1];
          token = $('.extole-js-email-panel-email-share-form').find('.extole-tokenizer-token').text();
          if (token) {
            obj = {
              'event_category': 'Refer_friend',
              'event_action': 'Refer triggered',
              'event_label': 'Refer_' + referMethod + ' - ' + document.location.href,
            };
            site.track.evtLink(obj);
          }
        });
      }(jQuery));
      // END CHECKOUT EVENTS

      // Alt image tracking start
      if ($('body').hasClass('section-product') && drupalAltImgSettings) {
        // Trigger Alt image event only when alternate images are available
        if ($('body').hasClass('device-pc') && $('.js-spp-carousel__thumbnail').length > 1) {
          trackAltImageTrackingClickPC();
          trackAltImageTrackingDragPC();
        } else if ($('body').hasClass('device-mobile')) {
          trackAltImageTrackingMobile();
        }
      }

      // Track Alt image click - desktop
      function trackAltImageTrackingClickPC() {
        $(document).on('click', '.js-spp-carousel__thumbnail', function() {
          altImageClickCheck = true;
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails = buildAltImageProductName($targetElem);
          var bgImageUrl = $targetElem.find('.product-full__carousel__slide-thumb-img').attr('src');
          if (bgImageUrl) {
            altImageName = buildAltImageFileName(bgImageUrl);
          }
          trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'click');
        });
      }

      // Track Alt image drag - desktop
      function trackAltImageTrackingDragPC() {
        $(document).on('afterChange', '.js-spp-carousel', function() {
          if (!altImageClickCheck) {
            var $targetElem = $(this);
            var altImageName = '';
            var prodDetails = buildAltImageProductName($targetElem);
            var bgImageUrl = $targetElem.find('.slick-current img').attr('src');
            if (bgImageUrl) {
              altImageName = buildAltImageFileName(bgImageUrl);
            }
            trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'drag');
          }
          altImageClickCheck = false;
        });
      }

      // Track Alt image swipe - Mobile
      function trackAltImageTrackingMobile() {
        $(document).on('afterChange', '.js-spp-carousel', function() {
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails = buildAltImageProductName($targetElem);
          var bgImageUrl = $targetElem.find('.slick-current img').attr('src');
          if (bgImageUrl) {
            altImageName = buildAltImageFileName(bgImageUrl);
          }
          trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'swipe');
        });
      }

      // Build Alt image product name
      function buildAltImageProductName(targetElem) {
        var prodElem = targetElem.closest('.js-product');
        var prodId = prodElem.attr('data-product-id');
        var prodName = prodElem.find('.product-full__details .product-full__name').text();
        return [prodName, prodId];
      }

      // Build Alt image file name
      function buildAltImageFileName(altImageUrl) {
        var altImageArray = altImageUrl.split('/');
        var altImageName = altImageArray[altImageArray.length - 1].split('.')[0];
        return altImageName;
      }

      // Track product thumbnail click event
      function trackThumbnailClickEvent(altImageName, prodName, prodId, eventType) {
        var obj = {
          'event_action': 'alt image - ' + altImageName + ' - ' + eventType,
          'event_label': prodName + ' - ' + prodId
        };
        site.track.productThumbnailClick(obj);
      }
      // Alt image tracking end


      self.attached = 1;
    }
  };

  // Home page analytics
  $(document).ready(function() {
    var trackingDataLayer = site.trackingDataLayer || {};
    var dataLayer = trackingDataLayer.data || {};
    if (dataLayer && dataLayer.page_type === 'home') {
      GlobalServiceBus.emit('home.page.loaded');
    }
  });
}(window.site || {}, jQuery, window.GlobalServiceBus || {}));
